import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	route: '',
};

const routeSlice = createSlice({
	name: 'route',
	initialState,
	reducers: {
		addRoute: (state, action) => {
			state.route = action.payload;
		},
	},
});

export const { addRoute } = routeSlice.actions;

export default routeSlice.reducer;
