import React from 'react';
import LoaderGif from '../../../images/loader.gif';
import './Loader.scss';

const Loader = () => {
	return (
		<div className='loader'>
			<img src={LoaderGif} alt='loader' />
		</div>
	);
};

export default Loader;
