import { configureStore } from '@reduxjs/toolkit';
import langReducer from '../components/features/lang/langSlice';
import routeReducer from '../components/features/route/routeSlice';

export const store = configureStore({
	reducer: {
		lang: langReducer,
		route: routeReducer,
	},
});
